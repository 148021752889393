import * as basicLightbox from "basiclightbox";
import { tryJSON } from "../utils/objects";

export default class KOLPopups {
  constructor(writeDocument) {
   this.setupSnippetsForDocument(writeDocument);
  }

  wrapVideos(content){
    if(content.includes("<iframe")){
      return `<div class="kol-full-video-embed-wrapper embed-responsive embed-responsive-16by9">
        ${content}
      </div>`
    }
    return content;
  }


  setupSnippetsForDocument(doc){
    let snippets = doc.querySelectorAll("[data-kol-snippet='popup']");

    snippets.forEach((snippet, index) => {
      let targetElement = snippet.getAttribute("data-target");
      let contentItem = false;

      if (targetElement){
        contentItem = doc.querySelector(targetElement);
      }
      else{
         contentItem = snippet.getAttribute("data-content");
         contentItem = tryJSON(contentItem, contentItem);
         contentItem = this.wrapVideos(contentItem);
         contentItem = `
            <style>
                    
            /* Rules Popup snippet */

            .basicLightbox{position:fixed;display:flex;justify-content:center;align-items:center;top:0;left:0;width:100%;height:100vh;background:rgba(0,0,0,.8);opacity:.01;transition:opacity .4s ease;z-index:9999;will-change:opacity}.basicLightbox--visible{opacity:1}.basicLightbox__placeholder{max-width:100%;transform:scale(.9);transition:transform .4s ease;z-index:1;will-change:transform}.basicLightbox__placeholder>iframe:first-child:last-child,.basicLightbox__placeholder>img:first-child:last-child,.basicLightbox__placeholder>video:first-child:last-child{display:block;position:absolute;top:0;right:0;bottom:0;left:0;margin:auto;max-width:95%;max-height:95%}.basicLightbox__placeholder>iframe:first-child:last-child,.basicLightbox__placeholder>video:first-child:last-child{pointer-events:auto}.basicLightbox__placeholder>img:first-child:last-child,.basicLightbox__placeholder>video:first-child:last-child{width:auto;height:auto}.basicLightbox--iframe .basicLightbox__placeholder,.basicLightbox--img .basicLightbox__placeholder,.basicLightbox--video .basicLightbox__placeholder{width:100%;height:100%;pointer-events:none}.basicLightbox--visible .basicLightbox__placeholder{transform:scale(1)}

            .basicLightbox__placeholder{
              overflow:hidden;
              color: white;
            }

            .kol-pop-up-wrapper{
              height: 100%;
            }

            .kol-pop-up-dialog{
              height: calc(100%);
              overflow: hidden;
            }

            .kol-pop-up-body{
            overflow-x: hidden;
            overflow-y: scroll;
            height: calc(100% - 40px);
            color: white !important;
            }

            .kol-pop-up-header .close{
            color: white;
                float: right;
            font-size: 21px;
            font-weight: bold;
            line-height: 1;
            opacity: 1;
            padding: 0;
            cursor: pointer;
            background: transparent;
            border: 0;
            -webkit-appearance: none;
            appearance: none;
            }

            .kol-pop-up-content{
            height:100%;
            max-width:960px;
            }

            .kol-

            .basicLightbox__placeholder{
            height: 100%;
            padding: 20px;
            }

            .kol-pop-up-wrapper .kol-full-video-embed-wrapper{
              width: 600px;
              max-width: 100%;
            }

            .kol-pop-up-title, .kol-pop-up-body p{
            color: white;
            }
          </style>
          <div class="kol-pop-up-wrapper" id="rulesModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">

          <div class="kol-pop-up-dialog" style="height: calc(100%);" role="document">
            <div class="kol-pop-up-content" style="height: 100%;">
              <div class="kol-pop-up-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <p class="kol-pop-up-title" style="font-weight: bold;" id="myModalLabel">&nbsp;</p>
              </div>
              <div class="kol-pop-up-body">


              ${contentItem}


            </div>
          </div>

        </div>
        </div>
            `
      }
      
      
      // if you want it to use set content on an attribute
      //let content = snippet.getAttribute("data-pop-up-content");
      if(contentItem){
        var basicBox = basicLightbox.create(contentItem, {
            className: "kol-lightbox-wrapper",
            onShow: (instance) => {
            instance.element().querySelector(".close").onclick = null;
            instance.element().querySelector(".close").onclick = instance.close;
          },
          });
        
          snippet.onclick = null;
          snippet.onclick = (event) => {
            event.preventDefault();
            basicBox.show();
          }
          
          
      }
   
     
    
    });
  }



}
