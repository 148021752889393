import { tryJSON } from '../utils/objects'
import { kolDebug as debug } from '../utils/debugging'
import { stringToHTML } from '../utils/dom'
import { extend } from 'lodash-es'

export default class KOLEmbedPage {
  constructor (options) {
    this.options = options
    this.setupEventListeners()
    this.handleEmbedLoad = function (event) {
      // remove the preview.
      debug('Handling Embed Frame Load', event)

      const embedPageFrame = document.querySelector('#' + event.currentTarget.id)

      if (embedPageFrame) {
        embedPageFrame.style.visibility = 'visible'
        embedPageFrame.setAttribute('data-kol-box-ready', true)
      }

      this.updateFrameHeight()
    }

    this.build()
  }

  handleURLChange (event) {
    debug('Handling URL change event in embed page box.')
    if (this) {
      this.updateFrameHeight()
    }
  }

  firstSizeUpdate (pageID, index) {
    this.checkCondition = function (attemptsMade) {
      const embedPageWrapper = top.document.getElementById(
        'kol-embed-page-frame-' + pageID + '-' + index
      )
      let resized = false
      if (embedPageWrapper) {
        resized = embedPageWrapper.getAttribute('data-kol-frame-resized') === 'true'
      }
      if (resized) {
        return true
      } else if (attemptsMade < 1000) {
        setTimeout(() => {
          debug('Checking for embed page frame resize.')
          this.updateFrameHeight()
          this.checkCondition(attemptsMade + 1)
        }, 10)
      } else {
        debug('Failed to resize embed page frame.')
      }
    }

    this.checkCondition(0)
  }

  handleMessage (event) {
    // get lead change from embed pages and pass it along.
    if (event.data.type === 'kol:leadchange') {
      console.log('kol:leadchange', event.data.data)
      window._kol.leads.pushLead(event.data.data)
    }

    // Need to check for safety as we are going to process only our messages
    // So Check whether event with data(which contains any object) contains our message here its "FrameHeight"
    if (event.data == 'getFrameHeight') {
      // event.source contains parent page window object
      // which we are going to use to send message back to main page here "abc.com/page"

      // parentSourceWindow = event.source;

      // Calculate the maximum height of the page
      const body = document.body; const html = document.documentElement
      body.style.height = 'auto'
      body.classList.add('kol-page-embedded')
      const sections = body.querySelectorAll('.is-section')
      let lastElementBottom = 0
      if (sections.length > 0) {
        const lastSection = sections[sections.length - 1]
        lastElementBottom = lastSection.getBoundingClientRect().bottom + window.scrollY
      }
      // const height = Math.max(body.scrollHeight, body.offsetHeight,
      //  html.clientHeight, html.scrollHeight, html.offsetHeight)
      const height = Math.max(body.scrollHeight, html.scrollHeight, lastElementBottom)

      debug('height options: ', { lastElementB: lastElementBottom, bodyScrollHeight: body.scrollHeight, bodyOffsetHeight: body.offsetHeight, htmlClientHeight: html.clientHeight, htmlScrollHeight: html.scrollHeight, htmlOffsetHeight: html.offsetHeight })

      debug('Posting message for embed frame height: ' + lastElementBottom + 'px')
      // Send height back to parent page "abc.com/page"
      event.source.postMessage({ FrameHeight: height, pageID: window.KOLSettings.page_id }, '*')
    }
    if (event.data.hasOwnProperty('FrameHeight') && event.data.hasOwnProperty('pageID')) {
      // Set height of the Iframe
   
      debug('Received message for embed frame height: ' + event.data.FrameHeight + 'px')
      const embedPageFrames = document.querySelectorAll('.kol-embed-page-frame-' + event.data.pageID);
      const newHeight = event.data.FrameHeight;
      embedPageFrames.forEach((embedPageFrame) => {
        if (embedPageFrame) {
        
          embedPageFrame.setAttribute('data-kol-frame-resized', 'true');
          embedPageFrame.style.height = newHeight + 'px'; // Ensure the variable matches case (FrameHeight to frameHeight if necessary)
        }
      });
    }
  }

  setupEventListeners () {
    // window.removeEventListener("message", this.handleMessage, false);
    debug('Setting up event listeners for embedded pages')
    window.addEventListener(
      'kol:leadchange',
      this.updateFrameHeight
    )
    window.addEventListener('message', this.handleMessage)

    if (!window._kol.mode.isDesigner && !window._kol.mode.isPreview) {
      window._kol.container.removeEventListener(
        'kol:url:changed',
        this.handleURLChange,
        true
      )
      window._kol.container.addEventListener('kol:url:changed', (event) =>
        this.handleURLChange(event)
      )
      window.removeEventListener('resize', this.updateFrameHeight)
      window.addEventListener('resize', this.updateFrameHeight)
    }
    this.updateFrameHeight()
  }

  build () {
    const snippets = document.querySelectorAll("[data-kol-snippet='embedpage']")
    let newEmbeds = 0
    const myself = this
    snippets.forEach((snippet, index) => {
      snippet.innerHTML = ''
      let snippetOptions = tryJSON(snippet.getAttribute('data-settings'), {
        embedHeight: 'full',
        pageID: snippet.getAttribute('data-kolPageId')
      })
      snippetOptions = extend({}, myself.defaultSettings(), snippetOptions)
      if (snippet.getAttribute('data-kolPageId')) {
        snippetOptions.pageID = snippet.getAttribute('data-kolPageId')
      }
      debug(
        'Writing embedded embed box frame with options:',
        snippetOptions
      )

      const embedHolder = stringToHTML(
        myself.getEmbedEmbedPageFrameMarkup(
          snippetOptions, index
        )
      )

      snippet.appendChild(embedHolder.firstElementChild)
      this.writeEmbedFrame(snippet, snippetOptions.pageID)
      this.firstSizeUpdate(snippetOptions.pageID, index)
      newEmbeds++
    })
    if (newEmbeds > 0) {
      this.updateFrameHeight()
    }
  }

  updateFrameHeight () {
    setTimeout(function () {
      const embedBoxFrames = document.querySelectorAll('.kol-embed-page-iframe')

      embedBoxFrames.forEach((embedBoxFrame) => {
        if (embedBoxFrame && embedBoxFrame.getAttribute('data-height') == 'full') {
          if (embedBoxFrame.contentWindow) {
            debug('posting message to embed to get the height.')
            embedBoxFrame.contentWindow.postMessage('getFrameHeight', '*')
          }
        }
      })
    }, 100)
  }

  defaultSettings () {
    return {
      embedHeight: 'full'
    }
  }

  getEmbedEmbedPageFrameMarkup (snippetOptions, index) {
    return `
      <div class="kol-embed-page-frame-wrapper" >
        <iframe id="kol-embed-page-frame-${snippetOptions.pageID}-${index}" allow="clipboard-write" class="kol-embed-page-iframe kol-embed-page-frame-${snippetOptions.pageID}" data-height="${
          snippetOptions.embedHeight
        }" data-heightPixels="${
      snippetOptions.embedHeightPixels
    }" name="kol-content-box-embed" style="height: ${
      snippetOptions.embedHeightPixels
    }px; width: 100%; border: 0px; scroll-margin-block-start: 0; !important;visibility:hidden;" frameBorder="0" class="kol-EmbedPage-embed-frame" data-kol-box-ready="false" ></iframe>

      <div>
    `
  }

  writeEmbedFrame (snippet, pageID) {
    debug('Writing embedded Embed Frame')

    // Select all frames with the class name matching 'kol-embed-page-frame-' + pageID
    const embedFrames = snippet.querySelectorAll('.kol-embed-page-frame-' + pageID)

    embedFrames.forEach(embedFrame => {
    // Add load event listener to each frame
      embedFrame.addEventListener('load', (event) =>
        this.handleEmbedLoad(event)
      )

      // Build the URL with parameters
      const params = new URLSearchParams(location.search)
      const currentFullUrl = `${location.protocol}//${location.hostname}${location.pathname}`
      params.set('__url', currentFullUrl)
      params.set('__embed', 'true')
      if (document.referrer) {
        params.set('__ref', document.referrer)
      }
      if (!params.get('kolid') && window._kol.analytics.user.cid) {
        params.set('kolid', window._kol.analytics.user.cid)
      }
      if (window._kol.analytics.user.uid) {
        params.set('__uid', window._kol.analytics.user.uid)
      }
      if (window._kol.analytics.user.sid) {
        params.set('__sid', window._kol.analytics.user.sid)
      }

      // Set the source for each frame
      embedFrame.src = window._kol.options.embed.url + '/' + pageID + '?' + params.toString()
      embedFrame.setAttribute('data-kol-page-ready', 'true')
      embedFrame.setAttribute('allow', 'clipboard-write')
    })
  }
}
