const getActivityIconClasses = (activityType) => {
  const split = activityType ? activityType.split('_') : ''

  const network = split.length >= 1 ? split[split.length - 1] : 'default'

  // now return icon for the network/channel
  switch (network) {
    case 'twitter':
      return 'fa-brands fab fa-x-twitter'
    case 'bandcamp':
      return 'fab fa-bandcamp'
    case 'facebook':
      return 'fa-brands fab fa-facebook-f'
    case 'goodreads':
      return 'fa-brands fab fa-goodreads-g'
    case 'fbmessenger':
      return 'fa-brands fab fa-facebook-messenger'
    case 'messenger':
      return 'fa-brands fab fa-facebook-messenger'
    case 'instagram':
      return 'fa-brands fab fa-instagram'
    case 'whatsapp':
      return 'fa-brands fab fa-whatsapp'
    case 'sms':
      return 'fa fa-commenting'
    case 'youtube':
      return 'fa-brands fab fa-youtube'
    case 'telegram':
      return 'fa-brands fab fa-telegram'
    case 'weibo':
      return 'fa-brands fab fa-weibo'
    case 'play':
      return 'fa-brands fab fa-android'
    case 'reddit':
      return 'fa-brands fab fa-reddit'
    case 'patreon':
      return 'fa-brands fab fa-patreon'
    case 'kickstarter':
      return 'fa-brands fab fa-kickstarter-k'
    case 'indiegogo':
      return 'fas fa-info fa-indiegogo'
    case 'producthunt':
      return 'fab fa-product-hunt'
    case 'cryptowallet':
      return 'fas fa-wallet'
    case 'spotify':
      return 'fab fa-spotify'
    case 'podcast':
      return 'fas fa-headphones'
    case 'digg':
      return 'fa-brands fab fa-digg'
    case 'wordpress':
      return 'fa-brands fab fa-wordpress'
    case 'tumblr':
      return 'fa-brands fab fa-tumblr'
    case 'pinterest':
      return 'fa-brands fab fa-pinterest'
    case 'emailsend':
      return 'far fa-envelope'
    case 'email':
      return 'far fa-envelope'
    case 'apple':
      return 'fa-brands fab fa-apple'
    case 'friends':
      return 'fas fa-bullhorn'
    case 'tiktok':
      return 'fa-brands fab fa-tiktok'
    case 'discord':
      return 'fa-brands fab fa-discord'
    case 'linkedin':
      return 'fa-brands fab fa-linkedin-in'
    case 'threads':
      return 'fab fa-threads'
    case 'phonenumber':
      return 'fas fa-mobile-alt'
    case 'text':
    case 'textarea':
    case 'dropdown':
    case 'multicheck':
    case 'singlecheck':
      return 'fas fa-edit'
    case 'smsverify':
      return 'far fa-badge-check'
    case 'twitch':
      return 'fa-brands fab fa-twitch'
    case 'google':
      return 'fa-brands fab fa-google'
    case 'file':
      return 'fa-solid fa-cloud-arrow-up'
    case 'webcam':
      return 'fa-solid fa-camera'
    default:
      return 'fas fa-external-link-alt'
  }
}

export default getActivityIconClasses
