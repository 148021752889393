import 'promise-polyfill/src/polyfill'
import 'whatwg-fetch'
import KA from './analytics'
import Leads from './leads'
import CampaignOptions from './campaign_options'
import KOLTag from './tag'
import Form from './form'
import UrlListener from './urllistener'
import campaignLogOut from './log_out'
import ContestBox from './contest_box'
import { buildSnippetUI } from './snippetUI/snippetUI'
import KOLEmbedContestBox from './snippetUI/embedcontestbox'
import KOLEmbedPage from './snippetUI/embedpage'
import KOLNudgeBar from './snippetUI/nudge-bar'
import ComponentRegister from './component_register'
import { ready } from './utils/events'
import { extendOverwrite } from './utils/objects'
import { captureShopifyCheckout, addCustomFieldsToCart } from './shopify'
import { kolWarn as warn, kolDebug as debug, clearState } from './utils/debugging'
import { VERSION } from './utils/version'
import { fetchPreviousForm } from './previous_form'
import { instantSignup } from './instant_signup'

export default class KOL {
  constructor (campaignId, optionOverrides = {}) {
    window._kol = window._kol || {}
    window._kol.component_register = new ComponentRegister(['KOLActivities'])
    debug('ContestBox Version', VERSION)
    this.ready = false
    this.loadDevEnv(optionOverrides)
    if (!optionOverrides.campaignId) {
      optionOverrides.campaignId = campaignId
    }
    const campaignOptions = new CampaignOptions(optionOverrides)
    window._kol.campaignOptions = campaignOptions
    campaignOptions.load().then((fullOptions) => {
      if (!fullOptions.valid) {
        warn(
          'Configuration could not be loaded. Using defaults where possible'
        )
      }
      this.options = extendOverwrite({}, fullOptions, optionOverrides)

      // just in case
      if (!('_kolDebuggingEnabled' in window)) {
        window._kolDebuggingEnabled = window.options.debug
      }

      window._kol.options = this.options
      this.debug = this.options.debug
      window._kol.form = new Form(this.options)
      const analytics = this.setUpAnalytics(this.options)
      fetchPreviousForm()

      addCustomFieldsToCart(campaignId)
      debug('Mode: ', window._kol.mode.mode)

      debug('Fetching lead data')
      this.addLeadChangeEventListener()
      if (this.canRenderOnPage()) {
        new KOLEmbedPage(this.options)
      }

      this._startUIWork = function () {
        buildSnippetUI(this.options, analytics)
        if (this.canRenderOnPage()) {
          /* eslint no-new: "off" */
          new KOLNudgeBar(window.document)
          window._kol.contest_box = new ContestBox(this.options)
          window._kol.contest_box_embed = new KOLEmbedContestBox(this.options) // noscript done
        }
      }
      this.loadLeadData(this.options, analytics)
        .then(() => {
          debug('Lead Data Fetched!')
          if (this.options.valid) {
            ready(this._startUIWork.bind(this))
          } else {
            warn('ContestBox skipped, no valid configuration')
          }
        })
        .then(captureShopifyCheckout)
        .catch((err) => {
          warn(err)
          warn('Lead lookup failed.')
        })

      debug('setting up tags')
      this.setUpTags(this.options, analytics)
      debug('tags enabled')
      if (window._kol.options.safe_mode !== true && !window._kol.mode.isDesigner && !window._kol.mode.isPreview) {
        debug('starting url listener')
        /* eslint no-new: "off" */
        new UrlListener()
      }

      // Branding in the console
      if (window._kol?.options?.configuration?.displayBranding) {
        console.log(
          'Contest forms powered by KickoffLabs. Learn more at https://kickofflabs.com.'
        )
      }

      window.dispatchEvent(new CustomEvent('kol:loaded', { detail: true, kol: this }))
    })
  }

  canRenderOnPage () {
    return (this.options.configuration.account_type !== 'park' && this.options.configuration.account_type !== 'downgraded') || _kol.mode.isDesigner === true
  }

  setUpAnalytics (options) {
    const analytics = new KA(options)
    window._kol.analytics = analytics
    if (!window.__kol_analytics) {
      window.__kol_analytics = analytics
    }

    if (analytics.trackViews && !window._kol.mode.isPreview && !window._kol.mode.isEmbed) {
      debug('Tracking View')
      analytics.trackView()
    } else {
      debug('View Tracking Sipped')
    }
    return analytics
  }

  logOut () {
    debug('LogOut Called')
    campaignLogOut()
  }

  loadLeadData (options, analytics) {
    debug('Setting up Lead Instance')
    window._kol.leads = this.leads_instance = new Leads(options, analytics)
    return this.leads_instance
      .cachedCurrentLeadData()
      .then((lead) => this.handleLeadQuery(lead))
      .then((lead) => this.handleInstantSignupLead(lead))
      .then((lead) => this.handleAnonymousLead(lead))
      .then(() => this.handleShareLead())
      .then(
        () =>
          (window._kol.lead =
            window._kol.currentLead || window._kol.anonymousLead)
      )
  }

  addLeadChangeEventListener () {
    window._kol.container.addEventListener('kol:leadchange', (event) => {
      const { lead } = event.detail
      debug('LEAD CHANGED TO', lead)
      window._kol.lead = lead
      if (lead.anonymous) {
        window._kol.anonymousLead = lead
      } else {
        window._kol.currentLead = lead
      }
    })
  }

  handleLeadQuery (lead) {
    if (lead) {
      window._kol.currentLead = lead
      const eventName = lead ? 'kol:knownlead' : 'kol:unknownlead'
      debug(`Current Lead Event: ${eventName}`, lead)
      const evt = new CustomEvent(eventName, {
        detail: { lead },
        bubbles: true
      })
      window.dispatchEvent(evt)

      return lead
    }
    return null
  }

  handleInstantSignupLead (lead) {
    if (!lead) {
      return instantSignup()
    }
    return lead
  }

  handleAnonymousLead (lead) {
    if (!lead) {
      return this.leads_instance.cachedAnonymousLead().then((anonLead) => {
        window._kol.anonymousLead = anonLead
        debug('Current Anonymous Lead Event:', anonLead)
        const evt = new CustomEvent('kol:anonymouslead', {
          detail: { lead: anonLead },
          bubbles: true
        })
        window.dispatchEvent(evt)
      })
    }
    return null
  }

  handleShareLead () {
    debug('Load Share Lead')
    return this.leads_instance.cachedShareLeadData().then((lead) => {
      if (lead) {
        window._kol.shareLead = lead
        const evt = new CustomEvent('kol:knownsharelead', {
          detail: { lead },
          bubbles: true
        })
        window.dispatchEvent(evt)
      }
    })
  }

  loadDevEnv (optionOverrides) {
    if (optionOverrides.useEnv) {
      warn('!!!!!LOADING ENV OVERRIDES!!!!!')

      if (process.env.CAMPAIGN_ID) {
        warn(`CAMPAIGN ID OVERRIDE ${process.env.CAMPAIGN_ID}`)
        optionOverrides.campaignId = process.env.CAMPAIGN_ID
      }

      if (process.env.CAMPAIGN_OPTIONS_URL) {
        warn(
          `CAMPAIGN OPTIONS URL OVERRIDE ${process.env.CAMPAIGN_OPTIONS_URL}`
        );

        (optionOverrides.campaignOptions =
          optionOverrides.campaignOptions || {}).url =
          process.env.CAMPAIGN_OPTIONS_URL
      }

      if (process.env.SHOPIFY_API_URL) {
        warn(`SHOPIFY API URL OVERRIDE ${process.env.SHOPIFY_API_URL}`);
        (optionOverrides.shopify = optionOverrides.shopify || {}).url =
          process.env.SHOPIFY_API_URL
      }

      if (process.env.FILESTACK_API_KEY) {
        warn(`FILESTACK API KEY OVERRIDE ${process.env.FILESTACK_API_KEY}`);
        (optionOverrides.fileStack = optionOverrides.fileStack || {}).apiKey =
          process.env.FILESTACK_API_KEY
      }

      if (process.env.LEADS_API_URL) {
        warn(`LEADS API URL OVERRIDE ${process.env.LEADS_API_URL}`);
        (optionOverrides.leads = optionOverrides.leads || {}).url =
          process.env.LEADS_API_URL
      }

      if (process.env.ANALYTICS_URL) {
        warn(`ANALYTICS URL OVERRIDE ${process.env.ANALYTICS_URL}`);
        (optionOverrides.analytics = optionOverrides.analytics || {}).url =
          process.env.ANALYTICS_URL
      }
    }
  }

  setUpTags (options, analytics) {
    const tag_instance = new KOLTag(this.options, analytics)

    // map old API to new instances
    _kol.tag = (...args) => {
      if (args.length === 2) {
        return tag_instance.addLeadToTagOld(...args)
      }
      return tag_instance.addLeadToTag(...args)
    }

    _kol.referral = (...args) => {
      if (args[0].tagId) {
        return tag_instance.addReferral(...args)
      }
      return tag_instance.addReferral({ tagId: args[0] })
    }
  }

  static fetchKolScriptBlock (id = 'koljs') {
    return document.getElementById(id)
  }

  static fetchKolScriptAttribute (optionName) {
    const scriptTag = this.fetchKolScriptBlock()
    if (scriptTag) {
      return scriptTag.getAttribute(optionName)
    }
    return null
  }

  static clear () {
    clearState()
  }
}
