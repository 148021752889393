import { getActivityUrl } from '../activities/activity_url'
import getFullActivityObjectById from '../activities/activity_helpers'
import getActivityDetailsHTML from '../activities/activity_html'
import getActivityIconClasses from '../activities/network_icons'
import NetworkScripts from '../activities/network_scripts'
import { postData } from '../utils/data'
import { kolDebug as debug } from '../utils/debugging'
import ComponentRegister from '../component_register'
import { tryJSON, tryColor, getBorderRadius } from '../utils/objects'
import KOLCountdownUtilities from '../utils/countdown-utilities'
export default class KOLActivities {
  constructor (options, analytics, writeDoc) {
    this.doc = writeDoc
    this.options = options || window._kol.options
    this.analytics = analytics || window._kol.analytics
    this.settings = {
      brandColor:
        _kol.options.onPageActions.contestBox.colors_primaryColor ||
        '#2095F2'
    }
    this.buildHandler = this.handleBuildByEvent.bind(this)
    this.leadChangeHandler = this.handleUpdateLead.bind(this)
    debug("Activities' Lead", window._kol.lead)
    this.build(options)
  }

  build (options) {
    debug('Start building activities snippet')
    if (window.Element && !Element.prototype.closest) {
      Element.prototype.closest = function (s) {
        const matches = (this.document || this.ownerDocument).querySelectorAll(s)
        let i
        let el = this
        do {
          i = matches.length
          while (--i >= 0 && matches.item(i) !== el) {}
        } while (i < 0 && (el = el.parentElement))
        return el
      }
    }
    const snippets = this.doc.querySelectorAll("[data-kol-snippet='actions']")

    // build UI for all activity holders.
    snippets.forEach((snippet, index) => {
      this.buildActivitySnippet(snippet, options)
      this.setupEventListenersOnSnippet(snippet)
    })
    new NetworkScripts(this.doc)

    // setup event handlers for activities.
    //
    this.setupEventListeners()
    ComponentRegister.component_ready('KOLActivities')
  }

  isActivityAForm (activity) {
    return activity.dataset.kolActivityType.includes('form_')
  }

  isActivityAnUpload (activity) {
    return activity.dataset.kolActivityType.includes('upload')
  }

  handleClickNoDetails (event) {
    const activity = event.target.closest('.kol-activity')
    if (this.isActivityAnUpload(activity)) {
      this.executeUploadAction(activity)
    } else if (!this.isActivityAForm(activity)) {
      if (this.canMarkActivityDone(activity)) {
        this.pushActivityDoneEvent(activity)
      } else {
        this.openClickThroughActivityURL(activity)
      }
    }
  }

  openClickThroughActivityURL (activity) {
    // Uploads need a third state. Do not open a new window or expand
    if (activity.dataset.kolActivityType.includes('upload_')) return
    const clickThrough = activity.classList.contains('kol-just-click-no-details-click') && activity.dataset.kolActivityType !== 'signup_facebook' && activity.dataset.kolActivityType !== 'signup_google'
    if (clickThrough) {
      const activityUrl = getActivityUrl(window._kol.options.actions, activity)
      // don't open a blank window for email.
      if (
        activity.dataset.kolActivityType === 'refer_email' ||
        activity.dataset.kolActivityType === 'send_emailsend'
      ) {
        window.top.location = activityUrl
      } else {
        try {
          debug('Trying to open url with window.open:', activityUrl)
          const newTab = window.open(
            activityUrl,
            '_blank'
          )
          if (!newTab) {
            debug('No Tab Found. Fallback opening URL ', activityUrl)
            window.top.location = activityUrl
          }
        } catch {
          debug('Fallback opening URL ', activityUrl)
          window.top.location = activityUrl
        }
      }
    }
  }

  handleActivityLogged (ev) {
    const response = ev.detail
    debug('Action Response', response)
  }

  markActivityDone (activity) {
    activity.setAttribute('data-kol-activity-completed', true)
    activity.classList.remove('kol-activity-not-completed')
    activity.classList.add('kol-activity-completed')
  }

  expandActivity (activity) {
    activity.classList.add('kol-activity-expanded')
    const evt = new CustomEvent('kol:activity-expanded', {
      detail: { activity }
    })
    window.dispatchEvent(evt)
  }

  collapseActivity (activity) {
    activity.classList.remove('kol-activity-expanded')
    const evt = new CustomEvent('kol:activity-closed', {
      detail: { activity }
    })
    window.dispatchEvent(evt)
  }

  toggleActivityExpandCollapse (activity) {
    if (activity.classList.contains('kol-activity-expanded')) {
      this.collapseActivity(activity)
    } else {
      this.expandActivity(activity)
    }
  }

  handActivityExpandCollapse (event) {
    const activity = event.target.closest('.kol-activity')
    this.toggleActivityExpandCollapse(activity)
  }

  handleBuildByEvent (event) {
    debug("Activities' Build By Event", event)

    const options = event.detail

    if (options !== false) {
      this.options = options
    }

    this.build(this.options)
  }

  handleUpdateLead (event) {
    debug('Activity - Rebuilding on lead change.', event)
    this.build(this.options)
  }

  setLeadsFromAnonymousAction (response) {
    _kol.leads.removeLeadFromCache()
    if (response.lead) {
      debug('Setting lead from Anonymous Action', response)
      _kol.leads.pushLead(response.lead)
    }
  }

  fireLoggingCompleteEvent (response) {
    const evt = new CustomEvent('kol:activity-logging-complete', {
      bubbles: true,
      detail: response
    })
    window.dispatchEvent(evt)
  }

  doRealTimePointUpdates (activity) {
    if (activity.dataset.kolActivityCompleted !== 'true') {
      const fa = getFullActivityObjectById(_kol.options.actions, activity.dataset.kolActivityId)
      debug('Temp adding UI Points ' + this.getActivityPoints(fa))
      _kol.lead.contest_score += this.getActivityPoints(fa)
      const evt = new CustomEvent('kol:activity-marked-done', {
        bubbles: true,
        detail: activity
      })
      activity.dispatchEvent(evt)
    }
  }

  collapseFormEntry (activity) {
    if (this.isActivityAForm(activity)) {
      this.collapseActivity(activity)
    }
  }

  canMarkActivityDone (activity) {
    switch (activity.dataset.kolActivityType) {
      case 'refer_friends':
      case 'leadtag_action':
      case 'signup_facebook':
      case 'signup_google':
        return false
      default:
        return true
    }
  }

  handleActivityDone (event) {
    const activity = event.detail.activity || event.detail
    debug('Activity', activity)

    // Don't report activity done if tweeted share.
    if (this.canMarkActivityDone(activity) && KOLCountdownUtilities.acceptNewSubscriptionsAndScoring()) {
      const data = {
        aid: activity.dataset.kolActivityId,
        data: tryJSON(activity.dataset.formdata || activity.dataset.uploaddata, {})
      }

      if (window._kol.lead.anonymous) {
        data.uid = window._kol.lead.id
      } else {
        data.kid = window._kol.lead.social_id
      }

      data.__source = _kol.analytics.getConversionData().__source

      debug('Logging Activity', data)

      postData(
        `${this.options.leads.url}/action/${window._kol.options.campaignId}`,
        data).then(this.setLeadsFromAnonymousAction)
        .then(this.fireLoggingCompleteEvent)
        .then(this.markActivityDone(activity))
        .then(this.doRealTimePointUpdates(activity))
        .then(this.openClickThroughActivityURL(activity))
        .then(this.collapseFormEntry(activity))
        .catch((error) => {
          debug('Error logging activity', error)
          if (activity.dataset.kolActivityType === 'form_smsverify' && error.response.status === 404) {
            alert('Sorry, we could not verify your sms verification code. Please be sure you entered the code correctly and try again.')
          }
        })
    }
  }

  handleActivityExpanded (event) {
    const { activity } = event.detail
    debug('Activity expanded:', activity)
    this.setupLinkedInEventListeners()
    // events where you get points for expansion...
    if (
      activity.classList.contains('kol-activity-view_post_instagram') ||
      activity.classList.contains('kol-activity-view_youtube') ||
      activity.classList.contains('kol-activity-view_spotify') ||
      activity.classList.contains('kol-activity-view_post_facebook') ||
      activity.classList.contains('kol-activity-view_tiktok') ||
      activity.classList.contains('kol-activity-awardpoints-expandAction')
    ) {
      this.pushActivityDoneEvent(activity)
    }
  }

  handleDetailedClick (event) {
    const activity = event.target.closest('.kol-activity')
    this.pushActivityDoneEvent(activity)
  }

  getSupportedFileStackLanguage (language) {
    // Remove any characters after the '-' (if present)
    const languageCode = language.split('-')[0]

    // List of valid language codes
    const supportedLanguages = ['ca', 'da', 'de', 'en', 'es', 'fr', 'he', 'it', 'ja', 'ko', 'nl', 'no', 'pl', 'pt', 'sv', 'ru', 'vi', 'zh', 'tr']

    // Check if the language code is in the valid list
    if (supportedLanguages.includes(languageCode)) {
      return languageCode
    } else {
      return 'en' // Default to English
    }
  }

  executeUploadAction (activity) {
    if (_kol.lead.anonymous) {
      const alertMessage = _kol?.options?.localization?.sign_up_first || 'Please sign up first.'
      alert(alertMessage)
      return
    }
    // If the activity is already completed, don't do anything
    if (activity.dataset.kolActivityCompleted === 'true') return

    const kolActivity = getFullActivityObjectById(_kol.options.actions, activity.dataset.kolActivityId)
    const fromSources = kolActivity.data.fromSources || ['local_file_system']
    const accept = kolActivity.data.accept || ['image/*']
    const client = filestack.init(_kol.options.fileStack.apiKey)
    const options = {
      uploadConfig: {
        tags: {
          campaignId: _kol.options.campaignId,
          activityId: activity.dataset.kolActivityId,
          // Use social_id. Directly after sign up we return a numeric for id
          leadId: _kol.lead.social_id
        }
      },
      lang: this.getSupportedFileStackLanguage(_kol.options.localization.language),
      accept,
      fromSources,
      onFileUploadFinished: (file) => {
        activity.setAttribute('data-uploaddata', JSON.stringify(file))
        this.pushActivityDoneEvent(activity)
      },
      acceptFn: (file, options) => {
        if (_kol.mode.isPreview || _kol.mode.isDesigner) {
          alert('Files are not uploaded when you are in a preview or in a KickoffLabs designer.')
          throw new Error('Files are not uploaded when you are in a preview or in a KickoffLabs designer.')
        } else {
          return Promise.resolve()
        }
      }
    }
    debug('Filestack Options', options)
    client.picker(options).open()
  }

  handleAddUpload (event) {
    event.preventDefault()
    const activity = event.target.closest('.kol-activity')
    this.executeUploadAction(activity)
  }

  handleAddFormData (event) {
    const activity = event.target.closest('.kol-activity')
    // find only input in that action.
    // need to handle empty form element.
    const inputs = activity.querySelectorAll('input, textarea, select')
    if (inputs) {
      const props = {}
      inputs.forEach(input => {
        if (input.type === 'checkbox') {
          if (input.checked) {
          // If the checkbox is checked, add the value to the existing values
          // If there is no value, store "true"
            const value = input.value || 'true'
            props[input.name] = props[input.name] ? `${props[input.name]},${value}` : value
          }
        } else if (input.value) {
          props[input.name] = input.value
        }
      })
      if (Object.keys(props).length > 0) {
        activity.setAttribute('data-formdata', JSON.stringify(props))
        this.pushActivityDoneEvent(activity)
      }
    }
  }

  handActivityCopyLink (event) {
    // Select the email link anchor text
    try {
      const copyLinkInputParent = event.target.closest(
        '.kol-copy-and-paste-sharelink-container'
      )
      const copyLinkInput = copyLinkInputParent.childNodes[1]

      if (!navigator.clipboard) {
        // This method is now obsolute in Chrome
        copyLinkInput.select()
        const successful = document.execCommand('copy')
        const msg = successful ? 'successful' : 'unsuccessful'
        if (msg === 'successful') {
          const copyLinkBtn = event.target.closest('.kol-copy-and-paste-icon')
          copyLinkBtn.innerHTML = '<i class="fas fa-check"></i>'
        }
      } else {
        navigator.clipboard.writeText(copyLinkInput.value).then(() => {
          const copyLinkBtn = event.target.closest('.kol-copy-and-paste-icon')
          copyLinkBtn.innerHTML = '<i class="fas fa-check"></i>'
        })
      }
    } catch (err) {
      console.log('Unable to copy')
    }
  }

  buildActivitySnippet (snippet, options) {
    // TODO: Clear existing HTML after done.

    const activityHTML = []
    // Don't just add. Remove if anything is there.
    snippet.innerHTML = ''
    if (options.actions && Array.isArray(options.actions)) {
      this.settings = tryJSON(
        snippet.getAttribute('data-settings'),
        this.settings
      )

      const darkerBrandColor = tryColor(this.settings.brandColor, '#333333')
      const borderRadius = getBorderRadius(this.settings)
      const backgroundColor = tryColor(this.settings.backgroundColor || '#FFFFFF', '#ffffff')
      const transparentTextColor = tryColor(
        this.settings.fontColor,
        '#111111'
      )
        .fade(0.75)

      const transparentTextColorLighter = tryColor(
        transparentTextColor,
        '#111111'
      )
        .fade(0.5)
      const style = `
        <style>
          #${snippet.id} .btn-add-form-data{
            background-color: ${this.settings.brandColor};
            border-color: ${this.settings.brandColor};
          }
          #${snippet.id} .kol-social-share-link{
            color: ${this.settings.brandColor};
          }

          #${snippet.id} .kol-social-share-link:hover{
            color: ${darkerBrandColor};
          }
          #${snippet.id} .btn-add-form-data:hover{
            background-color: ${darkerBrandColor};
            border-color:  ${darkerBrandColor};
          }
          #${snippet.id} .kol-activity-header {
              border-top-right-radius: ${borderRadius}px;
              border-top-left-radius: ${borderRadius}px;
              border-bottom-right-radius: ${borderRadius}px;
              border-bottom-left-radius: ${borderRadius}px;
          }
          #${snippet.id} .kol-activity{
            border-radius: ${borderRadius}px;
            background-color: ${backgroundColor} !important;
            border: .5px solid ${transparentTextColorLighter};
            box-shadow: 0 1px 3px ${transparentTextColorLighter}, 0 1px 2px ${transparentTextColor};
          }
          #${snippet.id} .kol-activity{
            color: ${this.settings.fontColor || '#333333'} !important;

          }
          #${snippet.id} .kol-activity-expanded .kol-activity-header
          {
            background: ${backgroundColor} !important;
            color: ${this.settings.fontColor || '#333333'} !important;
          }
          #${snippet.id} .kol-activity-details {
              border-bottom-right-radius: ${borderRadius}px;
              border-bottom-left-radius: ${borderRadius}px;
          }

          #${snippet.id} .kol-copy-and-paste-sharelink, #${snippet.id} .kol-copy-and-paste-icon {
              border: 1px solid  ${transparentTextColorLighter};
              background-color:  ${backgroundColor} ;
              color: ${this.settings.fontColor};
          }
          #${snippet.id} .kol-copy-and-paste-sharelink{
            border-radius: ${borderRadius}px !important;
          }
          #${snippet.id} .kol-copy-and-paste-icon {
            border-top-right-radius: ${borderRadius}px !important;
            border-bottom-right-radius: ${borderRadius}px !important;
          }



        </style>`
      activityHTML.push(style)
      if (options.actions.length > 0) {
        options.actions.forEach((activity) => {
          if (activity.enabled) {
            if (activity.type != 'form_smsverify' || !_kol.lead.anonymous) {
              activityHTML.push(this.getActivityHTML(activity))
            } else {
              debug('Skipped Action', activity)
            }
          }
        })
      } else {
        activityHTML.push("<div class='kol-no-actions'><h2>-</h2></div>")
      }
    }
    snippet.innerHTML = activityHTML.join('')
  }

  isActivityCompletedForLead (activity) {
    let completedActions = []
    if (_kol.lead && _kol.lead.completed_actions) {
      completedActions = _kol.lead.completed_actions
    }
    return completedActions.includes(activity.id)
  }

  getActivityExpandedClass (activity) {
    if (activity.type == 'refer_friends' && this.settings.expandReferFriends) {
      return 'kol-activity-expanded'
    }
    if (activity.type == 'form_smsverify' && !activity.completed) {
      return 'kol-activity-expanded'
    }
    return ''
  }

  getActivityHTML (activity) {
    // kol-activity-expanded to expand
    // { "id": 1, "url": "https://twitter.com/kickofflabs", "sort": 1586888879, "text": "Follow us on Twitter", "type": "click", "points": 50 },
    activity.completed = this.isActivityCompletedForLead(activity)
    const completedClass = activity.completed
      ? 'kol-activity-completed'
      : 'kol-activity-not-completed'
    const details = getActivityDetailsHTML(activity)
    const expand = details ? 'kol-expand-details' : 'kol-just-click-no-details'
    const expandedClass = this.getActivityExpandedClass(activity)
    const pointsClass =
      'kol-activity-awardpoints-' + activity.data.awardPointsOn
    return `<div id="activity-id-${
      activity.id
    }" class="kol-activity  kol-activity-${
      activity.type
    } ${completedClass} ${expandedClass} ${pointsClass} ${expand}-click" data-kol-activity-data="${
      activity.data
    }" data-kol-activity-type="${activity.type}" data-kol-activity-url="${
      activity.url
    }" data-kol-activity-id="${activity.id}" data-kol-activity-completed="${activity.completed}">
              <div class="kol-activity-header ${expand}">
                <div class="kol-activity-type">
                  <i class="${this.getIconOrDefault(
                    activity
                  )}" aria-hidden="true"></i>
                </div>
                <div class="kol-activity-description">${activity.text}</div>

                ${this.getActivityPointsHTML(activity)}
                <div class="kol-activity-expanded-icon-wrapper"><i class="fas fa-chevron-down" style="color:${
                  this.settings.brandColor
                } !important;" aria-hidden="true"></i></div>
              </div>
              <div class="kol-activity-details">
                ${details}
              </div>
            </div>
      `
  }

  getIconOrDefault (activity) {
    if (activity.data.icon !== undefined && activity.data.icon !== null) {
      return activity.data.icon
    } else {
      return getActivityIconClasses(activity.type)
    }
  }

  getActivityPointsHTML (activity) {
    const points = this.getActivityPoints(activity)
    const eachWord =
      activity.type === 'refer_friends'
        ? ' ' + _kol.options.localization.eachWord
        : ''
    let pointsHtml = `<div class="kol-activity-points" style="color:${
        this.settings.brandColor
      } !important;"><span class="kol-activity-status-completed"><i class="fa fa-check" style="color:${
        this.settings.brandColor
      } !important;"></i></span>`

    if (points > 0) {
      pointsHtml += `<span class="kol-activity-status-not-completed" style="color:${
        this.settings.brandColor
      } !important;"> + </span> ${
        this.getActivityPoints(activity) + eachWord
      }</div>`
    } else {
      pointsHtml += '</div>'
    }
    return pointsHtml
  }

  getActivityPoints (activity) {
    const points = activity.points

    if (activity.type != 'refer_friends') {
      return points || ''
    } else {
      if (this.options.scoring) {
        return this.options.scoring.require_verification == '1'
          ? this.options.scoring.direct_verified_referrals
          : this.options.scoring.direct_referrals
      }
    }
    return points
  }

  // method to capture linkedin click events by intent.
  setupLinkedInEventListeners () {
    const activityObject = this
    if (this.linkedInSetup == false) {
      this.doc.querySelectorAll('.kol-linkedin-follow-button').forEach((item) => {
        if (item.getAttribute('data-click-event') != true) {
          item.addEventListener('click', (event) => {
            const activity = event.target.closest('.kol-activity')
            activityObject.pushActivityDoneEvent(activity)
          })
          item.setAttribute('data-click-event', true)
        }
      })
      this.linkedInSetup = true
    }
    focus()
    const listener = window.addEventListener('blur', function () {
      if (this._kol.container.document.activeElement.parentNode.classList.contains('IN-widget')) {
        // clicked
        const activity = document.activeElement.closest('.kol-activity')
        activityObject.pushActivityDoneEvent(activity)
      }
      if (this._kol.container.document.activeElement?.contentDocument?.activeElement?.classList?.contains('IN-widget')) {
        // clicked
        const activity = this._kol.container.document.activeElement?.contentDocument?.activeElement?.closest('.kol-activity')
        activityObject.pushActivityDoneEvent(activity)
      }
      window.removeEventListener('blur', listener)
    })
  }

  pushActivityDoneEvent (activity) {
    const evt = new CustomEvent('kol:activity-done', {
      bubbles: true,
      detail: activity
    })
    activity.dispatchEvent(evt)
  }

  setupEventListenersOnSnippet (snippet) {
    // clear old listeners with clone
    const newSnippet = snippet.cloneNode(true)
    snippet.parentNode.replaceChild(newSnippet, snippet)
    newSnippet.addEventListener(
      'kol:activity-done',
      this.handleActivityDone.bind(this)
    )
  }

  setupEventListeners () {
    debug('Setup Actions Event Listeners')

    // Handle basic click actions.
    this.doc.querySelectorAll('.kol-just-click-no-details').forEach((item) => {
      item.removeEventListener('click', this.handleClickNoDetails, true)
      item.addEventListener('click', this.handleClickNoDetails.bind(this))
    })

    // Handle expansion - some get points for it.
    this.doc.querySelectorAll('.kol-expand-details').forEach((item) => {
      item.removeEventListener('click', this.handActivityExpandCollapse, true)
      item.addEventListener(
        'click',
        this.handActivityExpandCollapse.bind(this)
      )
    })

    // Handle click inside of details for points.
    this.doc.querySelectorAll('.kol-detail-click-points').forEach((item) => {
      item.removeEventListener('click', this.handleDetailedClick, true)
      item.addEventListener('click', this.handleDetailedClick.bind(this))
    })

    // Handle adding form data for points.
    this.doc.querySelectorAll('.btn-add-form-data').forEach((item) => {
      item.removeEventListener('click', this.handleAddFormData, true)
      item.addEventListener('click', this.handleAddFormData.bind(this))
    })

    this.doc.querySelectorAll('.btn-file-upload').forEach((item) => {
      item.removeEventListener('click', this.handleAddUpload, true)
      item.addEventListener('click', this.handleAddUpload.bind(this))
    })

    window._kol.container.addEventListener(
      'kol:snippets:build:actions',
      this.buildHandler
    )

    window._kol.container.removeEventListener(
      'kol:leadchange',
      this.leadChangeHandler
    )
    window._kol.container.addEventListener(
      'kol:leadchange',
      this.leadChangeHandler
    )

    document.removeEventListener('kol:knownlead', this.leadChangeHandler)
    document.addEventListener('kol:knownlead', this.leadChangeHandler)

    window._kol.container.removeEventListener(
      'kol:activity-expanded',
      this.handleActivityExpanded.bind(this),
      true
    )
    window._kol.container.addEventListener(
      'kol:activity-expanded',
      this.handleActivityExpanded.bind(this)
    )

    window._kol.container.removeEventListener(
      'kol:activity-logging-complete',
      this.handleActivityLogged,
      true
    )
    window._kol.container.addEventListener(
      'kol:activity-logging-complete',
      this.handleActivityLogged
    )

    this.doc.querySelectorAll('.kol-copy-and-paste-icon').forEach((item) => {
      item.removeEventListener('click', this.handActivityCopyLink, true)
      item.addEventListener('click', this.handActivityCopyLink.bind(this))
    })
  }
}
