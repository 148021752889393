import { kolDebug as debug } from '../utils/debugging'
import { stringToHTML } from '../utils/dom'
import { extendOverwrite } from '../utils/objects'
import storage from 'local-storage-fallback'

export default class KOLNudgeBar {
  constructor (writeDoc) {
    const myself = this
    this.doc = writeDoc
    this.options = window._kol.options

    const defaultOptions = {
      font: 'open-sans',
      showCloseButton: true,
      backgroundColor: '#ffffff',
      textColor: '#000000',
      buttonColor: '#000000',
      buttonTextColor: '#ffffff',
      displayBar: true,
      verificationNudge: {
        enabled: true,
        verificationText: 'Please check your email and click the verification link.'
      },
      shareLeadNudge: {
        enabled: true,
        shareLeadText: '{{display_name}} invited you to join!',
        buttonText: 'Join Now',
        buttonAction: false,
        buttonCustomUrl: 'https://kickofflabs.com'
      },
      noLeadNudge: {
        enabled: false,
        nudgeText: 'Join our for exclusive access today!',
        buttonAction: false,
        buttonText: 'Join Now',
        buttonCustomUrl: 'https://kickofflabs.com'
      },
      leadNudge: {
        enabled: false,
        nudgeText: 'Join our for exclusive access today!',
        buttonAction: false,
        buttonText: 'Join Now',
        buttonCustomUrl: 'https://kickofflabs.com'
      }
    }

    this.nudgeBarOptions =
      this.options &&
      this.options.onPageActions &&
      this.options.onPageActions.nudgeBar

    this.nudgeBarOptions = extendOverwrite(
      {},
      defaultOptions,
      this.nudgeBarOptions || {}
    )

    debug('Nudge Bar Options', this.nudgeBarOptions)

    this.handleBuildByEvent = function (event) {
      myself.build()
    }
    this.handleMessage = function (event) {
      if (event.data.hasOwnProperty('NudgeFrameHeight')) {
        debug("Nudge Bar's Frame Height Message", event.data.NudgeFrameHeight);
        // Set height of the Iframe
        const newHeight = Math.max(event.data.NudgeFrameHeight, 60)
        const barFrame = window.top.document.getElementById('kol-nudge-bar-top-frame')
        const barBg = window.top.document.getElementById('kol-nudge-bar-background')

        if (barBg && barFrame) {
          if (!window._kol.mode.isDesigner) {
            myself.setBodyMarginPadding()
            myself.pushFixedElementsDown(newHeight)
          }

          if (barBg) {
            barBg.style.height = (newHeight - 4) + 'px'
          }
          if (barFrame) {
            barFrame.style.height = newHeight + 'px'
            const buttons = barFrame.contentWindow.document.querySelectorAll('.nudge-btn')
            // Loop through each element and add class "new-class"
            buttons.forEach(function (element) {
              element.classList.add('wiggle')
            })
          }
        }
      }
    }
    this.build()

    this.setupEventListeners()
  }

  handleBuildByDesigner (event) {
    debug('Sidebar Designer Build Event', event)

    _kol.options.onPageActions.nudgeBar = extendOverwrite(
      {},
      this.nudgeBarOptions,
      _kol.options.onPageActions.nudgeBar,
      event.detail
    )

    this.nudgeBarOptions = _kol.options.onPageActions.nudgeBar
    this.build()
  }

  handleKnownShareLead (event) {
    debug('Share Lead nudge bar Starting', lead || 'Not Available')
    const lead = event.detail.lead
    this.build(lead)
  }

  setupEventListeners () {
    window.removeEventListener(
      'kol:knownsharelead',
      this.handleKnownShareLead,
      true
    )
    window.addEventListener('kol:knownsharelead', (event) =>
      this.handleKnownShareLead(event)
    )
    window.removeEventListener('resize', this.updateFrameHeight)
    window.addEventListener('resize', this.updateFrameHeight)
    window.addEventListener('message', this.handleMessage)

    window.removeEventListener('kol:onpage:nudgebar:designer:build', this.handleBuildByDesigner)
    window.addEventListener('kol:onpage:nudgebar:designer:build', (event) =>
      this.handleBuildByDesigner(event)
    )
  }

  nudgeAgain (nudgeName) {
    const nudgeLastClosed = storage.getItem(`kola.${this.options.campaignId}.nudgelastclosed.${nudgeName}`)
    if (!nudgeLastClosed || window._kolDebuggingEnabled) {
      debug('Nudge Last Closed', nudgeLastClosed)
      return true
    } else {
      debug('Nudge Last Closed', nudgeLastClosed)
      const now = new Date()
      const days = (now - nudgeLastClosed) / (1000 * 3600 * 24)
      if (days > 1) {
        return true
      }
    }
    return false
  }

  showNoLeadNudge () {
    return (this.nudgeBarOptions?.forceNudge == 'noLeadNudge' || (this.nudgeAgain('noLeadNudge') && _kol.lead.anonymous && this.nudgeBarOptions?.noLeadNudge?.enabled == true))
  }

  showLeadNudge () {
    return (this.nudgeBarOptions?.forceNudge == 'leadNudge' || (!_kol.lead.anonymous && this.nudgeAgain('leadNudge') && this.nudgeBarOptions?.leadNudge?.enabled == true))
  }

  showVerificationNudge () {
    return (this.nudgeBarOptions?.forceNudge == 'verificationNudge' || (!_kol.lead.anonymous && this.nudgeAgain('verificationNudge') && !_kol.lead.verified && _kol.options?.configuration?.verificationEnabled && this.nudgeBarOptions?.verificationNudge?.enabled == true))
  }

  showShareLeadNudge () {
    return (this.nudgeBarOptions?.forceNudge == 'shareLeadNudge' || (_kol.lead.anonymous && this.nudgeAgain('shareLeadNudge') && _kol.shareLead && this.nudgeBarOptions?.shareLeadNudge?.enabled == true))
  }

  hasNudgeToDisplay () {
    return (this.showShareLeadNudge() || this.showVerificationNudge() || this.showLeadNudge() || this.showNoLeadNudge())
  }

  isOkToBuildBar () {
    return (_kol.options.onPageActions && _kol.options.onPageActions.nudgeBar && _kol.options.onPageActions.nudgeBar.displayBar == true && this.hasNudgeToDisplay())
  }

  getOnPageStyles () {
    return `
        <style>
        @-webkit-keyframes kol-bounce {
          0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(-50px);}
          40% {-webkit-transform: translateY(-30px);}
          60% {-webkit-transform: translateY(-15px);}
        }

        @keyframes kol-bounce {
          0%, 20% {
            transform: translateY(-125px);
          }
          50% {
            transform: translateY(15px);
          }
          70% {
            transform: translateY(-30px);
          }
          90% {
            transform: translateY(10px);
          }
          100% {
            transform: translateY(0);
          }
        }

        .kol-bounce {
            -webkit-animation-name: kol-bounce;
            animation-name: kol-bounce;
        }
      </style>
    `
  }

  setBodyMarginPadding () {
    const bodyStyles = window.getComputedStyle(document.body)
    const paddingTop = parseInt(bodyStyles.getPropertyValue('padding-top'))
    const marginTop = parseInt(bodyStyles.getPropertyValue('margin-top'))
    if (marginTop == 0 && paddingTop == 0) {
      return
    }
    document.body.style.marginTop = '0px'
    document.body.style.paddingTop = '0px'
    const nudgeBarBackground = document.getElementById('kol-nudge-bar-background')
    nudgeBarBackground.setAttribute('data-body-padding-top', paddingTop)
    nudgeBarBackground.setAttribute('data-body-margin-top', marginTop)
    nudgeBarBackground.style.marginBottom = (marginTop + paddingTop) + 'px'
  }

  resetBodyMarginPadding () {
    const nudgeBarBackground = document.getElementById('kol-nudge-bar-background')
    const marginTop = nudgeBarBackground.getAttribute('data-body-margin-top')
    const paddingTop = nudgeBarBackground.getAttribute('data-body-padding-top')
    document.body.style.marginTop = marginTop + 'px'
    document.body.style.paddingTop = paddingTop + 'px'
    nudgeBarBackground.style.marginBottom = '0px'
  }

  resetFixedElements () {
    const elementsToReset = document.querySelectorAll('[data-reset-top-value]')

    for (let i = 0; i < elementsToReset.length; i++) {
      const resetTopValue = parseInt(elementsToReset[i].getAttribute('data-reset-top-value'))
      if (!isNaN(resetTopValue)) {
        elementsToReset[i].style.marginTop = '0px'
        elementsToReset[i].style.top = resetTopValue + 'px'
      }
    }
  }

  pushFixedElementsDown (pushHeight) {
    const elements = document.querySelectorAll('body *')
    const fixedElements = Array.from(elements).filter(element => {
      const styles = window.getComputedStyle(element)
      return element.getBoundingClientRect().top <= 100 && styles.getPropertyValue('position') === 'fixed' && element !== this.doc.getElementById('kol-nudge-bar-top-frame')
    })
    for (let i = 0; i < fixedElements.length; i++) {
      const currentTop = parseInt(fixedElements[i].style.top) || 0
      fixedElements[i].setAttribute('data-reset-top-value', currentTop)
    }
    const elementsToPush = document.querySelectorAll('[data-reset-top-value]')
    for (let i = 0; i < elementsToPush.length; i++) {
      const currentTop = parseInt(elementsToPush[i].style.top) || 0
      elementsToPush[i].style.transition = 'margin .25s ease 0s'
      elementsToPush[i].style.marginTop = `${currentTop + pushHeight}px`
    }
  }

  build () {
    // remove any existing bars
    const snippets = this.doc.querySelectorAll('.kol-nudge-bar-background, .kol-top-bar-button-frame')
    snippets.forEach(snippet => {
      snippet.remove()
    })

    if (this.isOkToBuildBar()) {
      let barBackground = stringToHTML(`<div id="kol-nudge-bar-background" class="kol-nudge-bar-background kol-nudge-bar-position-top" style="background-color:${this.nudgeBarOptions.backgroundColor} ; position: relative; transition: height .25s ease 0s; height: 0px;">${this.getOnPageStyles()}</div> `)
      let barFrame = stringToHTML(this.top_bar_frame())
      const designPreviewHolder = this.doc.getElementById('embed_bar_preview_holder')

      if (designPreviewHolder) {
        designPreviewHolder.appendChild(barFrame.firstElementChild)
        designPreviewHolder.appendChild(barBackground.firstElementChild)
      } else {
        this.doc.body.insertBefore(barBackground.firstElementChild, this.doc.body.firstChild)
        this.doc.body.insertBefore(barFrame.firstElementChild, this.doc.body.firstChild)
      }

      barBackground = document.getElementById('kol-nudge-bar-background')
      barFrame = document.getElementById('kol-nudge-bar-top-frame')
      const barFrameContent = this.top_bar()

      barFrame.contentWindow.document.open('text/html', 'replace')
      barFrame.contentWindow.document.write(barFrameContent)
      barFrame.contentWindow.document.close()

      const closeBtn = barFrame.contentWindow.document.getElementById(
        'close'
      )
      if (closeBtn) {
        const self = this
        closeBtn.onclick = () => {
          barBackground.classList.add('kol-nudge-bar-hidden')
          barBackground.style.height = '0px'
          barFrame.style.height = '0px'
          barFrame.classList.add('kol-nudge-bar-hidden')

          const closeNudge = barFrame.contentWindow.document.querySelector('#close').getAttribute('data-close-nudge')
          storage.setItem(
            `kola.${this.options.campaignId}.nudgelastclosed.${closeNudge}`,
            Date.now()
          )
          self.resetFixedElements()
          setTimeout(function () {
            self.resetBodyMarginPadding()
          }, 250)
        }
      }

      this.setActiveNudge()
      this.updateFrameHeight()
    }
  }

  updateFrameHeight () {
    setTimeout(function () {
      const barFrame = document.getElementById('kol-nudge-bar-top-frame')

      if (barFrame && barFrame.contentWindow && barFrame.classList.contains('kol-nudge-bar-hidden') == false) {
        debug('posting message to nudgeBar to get the height.')
        barFrame.contentWindow.postMessage('NudgeFrameHeight', '*')
      }
    }, 500)
  }

  activateNudge (makeActive) {
    const barFrame = document.getElementById('kol-nudge-bar-top-frame')
    if (barFrame && barFrame.contentWindow) {
      const nudges = barFrame.contentWindow.document.querySelectorAll('.kol-nudge-wrapper')
      // Loop through each element and add class "new-class"
      nudges.forEach(function (element) {
        element.classList.remove('active')
      })
      const close = barFrame.contentWindow.document.querySelector('#close')
      if (close) {
        close.setAttribute('data-close-nudge', makeActive)
      }
      const activate = barFrame.contentWindow.document.querySelector('.kol-nudge-bar-' + makeActive + '-wrapper')
      // Loop through each element and add class "new-class"
      if (activate) {
        activate.classList.add('active')
      }
    }
  }

  setActiveNudge () {
    // force option used in the nudge designer or private settings.
    if (this.nudgeBarOptions.forceNudge) {
      this.activateNudge(this.nudgeBarOptions.forceNudge)
    } else {
      if (this.showVerificationNudge()) {
        this.activateNudge('verificationNudge')
        return true
      }
      if (this.showLeadNudge()) {
        this.activateNudge('leadNudge')
        return true
      }
      if (this.showShareLeadNudge()) {
        this.activateNudge('shareLeadNudge')
        return true
      }
      if (this.showNoLeadNudge()) {
        this.activateNudge('noLeadNudge')
        return true
      }
    }
  }

  top_bar_frame () {
    return `
      <iframe id="kol-nudge-bar-top-frame" name="top-bar-frame" src="about:blank" class="kol-top-bar-button-frame kol-bounce kol-nudge-bar-wrapper"
        style="border-width: initial;
        border-style: none;
        border-color: initial;
        border-image: initial;
        width:100%;
        height:60px;
        position: fixed;
        animation-duration: 2s;
        animation-fill-mode: forwards; /* Keep the final state of the animation */
        animation-timing-function: ease-in-out; /* Apply easing to the animation */
        box-shadow: 0 3px 3px rgba(0,0,0,.12), 0 2px 2px rgba(0,0,0,.24);
        top:0px;
        z-index:10000;">
      </iframe>
    `
  }

  getKickoffLabsBranding () {
    // check for branding

    if (_kol?.options?.configuration?.displayBranding) {
      return `<div class="nudge-bar-branding-wrapper"><a href="https://kickofflabs.com/?utm_source=kickofflabs&utm_term=customer_onpage&utm_medium=nudge_bar&utm_content=list_id_${_kol.options.campaignId}&utm_campaign=branding" target="_top" title="powered by kickofflabs">
              <img class="kol-brand-image" src="https://s3.amazonaws.com/static.kickoffpages.com/images/beaker_on_light70x70.png" alt="Powered by KickoffLabs"  />
              </a></div>`
    }
    return ''
  }

  getCloseButton () {
    if (this.nudgeBarOptions.showCloseButton) {
      return '<div class="kol-nudge-bar-close-wrapper"><button type="button" id="close" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>'
    } else {
      return ''
    }
  }

  getShareLeadText () {
    let shareLeadText = this.nudgeBarOptions.shareLeadNudge.shareLeadText
    if (shareLeadText.indexOf('{{display_name}}') > -1) {
      shareLeadText = shareLeadText.replace('{{display_name}}', _kol.shareLead.display_name)
    }
    return shareLeadText
  }

  getNudgeButton (forNudge) {
    const buttonOptions = this.nudgeBarOptions[forNudge]
    if (buttonOptions.buttonAction && (buttonOptions.buttonAction != false && buttonOptions.buttonAction != 'false')) {
      let buttonUrl = buttonOptions.buttonCustomUrl
      let onclick = ''
      switch (buttonOptions.buttonAction) {
        case 'defaultPage':
          buttonUrl = _kol?.lead?.social_url
          break
        case 'defaultThanksPage':
          buttonUrl = _kol?.lead?.status_url || this.getFallbackDefaultThanksPage()
          break
        case 'popupBox':
          buttonUrl = '#'
          onclick = 'window.openContestBox();'
          break
      }

      const button = `<div class="nudge-bar-button-wrapper"><a href="${buttonUrl}" target="_top" onclick="${onclick}" class="nudge-btn" style="background-color:${this.nudgeBarOptions.buttonColor}; border-color:${this.nudgeBarOptions.buttonColor}; color:${this.nudgeBarOptions.buttonTextColor};">${buttonOptions.buttonText || 'Press Me!'}</a></div>`
      return button
    }
    return ''
  }

  getFallbackDefaultThanksPage () {
    if (_kol?.options?.redirect_url) {
      const url = new URL(_kol?.options?.redirect_url)
      url.searchParams.set('kolid', _kol?.lead?.social_id)
      return url.toString()
    } else {
      return '?kolid=' + _kol?.lead?.social_id
    }
  }

  getNudgeImage (image_options) {
    if (image_options.url) {
      return `<div class="kol-nudge-bar-image-wrapper" style="${image_options?.url ? '' : 'display:none'}">
            <img class="kol-nudge-bar-image" src="${image_options?.url}" alt="${image_options?.name}">
          </div>`
    }
    return ''
  }

  getNudge (options) {
    return `
      <div class="kol-nudge-wrapper kol-nudge-bar-${options.name}-wrapper">
        <div class="kol-nudge-bar-inner">
          ${this.getNudgeImage(options.image)}
          <div class="kol-nudge-bar-text-wrapper">
            ${options.text}
          </div>
          ${this.getNudgeButton(options.name)}
        </div>
      </div>
    `
  }

  getLeadNudge () {
    const displayImage = { url: _kol?.lead?.avatar, name: _kol?.lead?.username }
    return this.getNudge({ name: 'leadNudge', image: displayImage, text: this.nudgeBarOptions?.leadNudge?.nudgeText })
  }

  getNoLeadNudge () {
    const displayImage = { url: _kol?.lead?.avatar, name: _kol?.lead?.username }
    return this.getNudge({ name: 'noLeadNudge', image: displayImage, text: this.nudgeBarOptions?.noLeadNudge?.nudgeText })
  }

  getShareLeadNudge () {
    if (_kol.shareLead == null) {
      return ''
    } else {
      const displayImage = { url: _kol?.shareLead?.avatar, name: _kol?.shareLead?.username }
      return this.getNudge({ name: 'shareLeadNudge', image: displayImage, text: this.getShareLeadText() })
    }
  }

  getVerificationNudge () {
    const displayImage = { url: _kol?.lead?.avatar, name: _kol?.lead?.username }

    if (this.showVerificationNudge() == false) {
      return ''
    } else {
      return this.getNudge({ name: 'verificationNudge', image: displayImage, text: this.nudgeBarOptions?.verificationNudge?.verificationText })
    }
  }

  top_bar (options) {
    const customHtmlHead = this.nudgeBarOptions.customHtmlHead
      ? this.nudgeBarOptions.customHtmlHead
      : ''
    return `
        <html>
        <head>
          <style>
            body {
              margin: 0;
              padding: 0;
              background-color: ${this.nudgeBarOptions.backgroundColor};
            }
            h1,h2,h3,h4,h5,h6 {
              font-family: '${this.nudgeBarOptions.font.replace(/\+/g, ' ')}', Helvetica, Arial, sans-serif;
              font-weight: 700;
            }

            body, p, input {
              font-family: '${this.nudgeBarOptions.font.replace(/\+/g, ' ')}', Helvetica, Arial, sans-serif;

            }

            #kol-nudge-bar-wrapper {
              width: 100%;
              background-color: ${this.nudgeBarOptions.backgroundColor};
            }
            .kol-nudge-bar-inner {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              height: auto;
              padding: 5px 50px;
            }
            .kol-nudge-bar-image{
              height:40px;
              border-radius: 50%;
            }
            .kol-nudge-bar-close-wrapper .close{
              color: ${this.nudgeBarOptions.textColor};
              font-size: 21px;
              line-height: 1;
              opacity: 1;
              padding: 0;
              cursor: pointer;
              background: transparent;
              border: 0;
              -webkit-appearance: none;
              appearance: none;
            }

            .kol-nudge-wrapper{
              display:none;
            }

            .kol-nudge-wrapper.active{
              display:block;
            }

            .nudge-bar-branding-wrapper{
              float: left;
              position: absolute;
              top: 0px;
              left: 15px;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .kol-brand-image{
              height: 35px;
              width: 35px;
            }

            .kol-nudge-bar-close-wrapper{
              float: right;
              position: absolute;
              top: 0px;
              right: 15px;
              height: 100%;
              display: flex;
            }

            @keyframes wiggle {
                0% { transform: rotate(0deg); }
              80% { transform: rotate(0deg); }
              85% { transform: rotate(5deg); }
              95% { transform: rotate(-5deg); }
              100% { transform: rotate(0deg); }
            }

            .wiggle{
              animation: wiggle 2.5s 5;
            }



            .nudge-btn{
              display: inline-flex;
              align-items: center;
              vertical-align: middle;
              margin: 0;
              width: auto;
              white-space: normal;
              padding: 0 1.33em;
              font-weight: 400;
              line-height: 1.5;
              text-align: center;
              text-decoration: none;
              cursor: pointer;
              -webkit-user-select: none;
              -moz-user-select: none;
              user-select: none;
              background-color: transparent;
              border: 1px solid transparent;
              padding: 0.15rem 0.75rem;
              font-size: 18px;
              border-radius: 4px;
              transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            }

            .nudge-btn:hover{
              animation: none;
            }

            .kol-nudge-bar-text-wrapper{
              color: ${this.nudgeBarOptions.textColor};
              padding: 15px 15px;
              font-size: 18px;
              text-align: center;
            }

            @media (max-width: 768px){
              .nudge-btn, .kol-nudge-bar-text-wrapper{
                font-size: 16px;
              }
            }
            @media (max-width: 576px){
              .kol-nudge-bar-inner{
                flex-direction: column;
                padding: 5px 15px;
              }
              .nudge-btn, .kol-nudge-bar-text-wrapper{
                font-size: 14px;
              }
              .nudge-btn{
                padding: 0.15rem 0.5rem;
              }
              .kol-nudge-bar-close-wrapper {
                right: 10px;
                height: auto;
                top: 7px;
              }
              .kol-nudge-bar-text-wrapper{
                padding: 5px 15px
              }
              .nudge-bar-branding-wrapper{
                left: 10px;
                top:10px;
                height: auto;
                align-items: flex-start;
              }
              .kol-brand-image{
                height: 25px;
                width: 25px;
              }
            }


          </style>
          <script>
            function openContestBox(){
              const openEvent = new CustomEvent("kol:activate:ContestBox", {});
              //Don't run if KOL script isn't installed.
              window.top._kol.container.dispatchEvent(openEvent);
              return false;
            }
            function handleMessage(event) {
              // Need to check for safety as we are going to process only our messages
              // So Check whether event with data(which contains any object) contains our message here its "FrameHeight"
              if (event.data == "NudgeFrameHeight") {
                console.log("got request for nudge height")
                //event.source contains parent page window object
                //which we are going to use to send message back to main page here "abc.com/page"

                //parentSourceWindow = event.source;
                //Calculate the maximum height of the page
                var body = document.body, html = document.documentElement;
                body.style.height = "auto";
                let wrapperHeight = document.getElementById("kol-nudge-bar-wrapper").offsetHeight;
                var height = Math.max(body.scrollHeight, body.offsetHeight,
                    html.clientHeight, html.scrollHeight, html.offsetHeight);

                // Send height back to parent page "abc.com/page"
                  event.source.postMessage({ "NudgeFrameHeight": wrapperHeight }, "*");
              }
            }
            window.addEventListener('message', this.handleMessage );

          </script>
          ${customHtmlHead}
        </head>
        <body>
        <div id="kol-nudge-bar-wrapper">
          ${this.getKickoffLabsBranding()}
          ${this.getShareLeadNudge()}
          ${this.getVerificationNudge()}
          ${this.getNoLeadNudge()}
          ${this.getLeadNudge()}
          ${this.getCloseButton()}
        </div>
        </body>
        </html>

    `
  }
}
