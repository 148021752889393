import getActivityIconClasses from './network_icons'
import { getNetworkShareURL } from './activity_url'
import KOLStrings from '../utils/strings'

const getActivityDetailsHTML = (activity) => {
  switch (activity.type) {
    case 'refer_friends':
      return getReferFriendsDetails(activity)
    case 'follow_twitter':
      return getTwitterFollowDetails(activity)
    case 'like_twitter':
      return getTweetEmbed(activity)
    case 'send_sms':
      return getSMSButton(activity)
    case 'tweet_twitter':
      return getTweetButton(activity)
    case 'retweet_twitter':
      return getReTweetButton(activity) + getTweetEmbed(activity)
    case 'view_post_instagram':
      return getInstagramPost(activity)
    case 'view_youtube':
      return getYouTubeVideo(activity)
    case 'follow_linkedin':
      return getLinkedInFollow(activity)
    case 'share_linkedin':
      return getLinkedInShare(activity)
    case 'view_post_facebook':
      return getFacebookPost(activity)
    case 'view_tiktok':
      return getTikTokEmbed(activity)
    case 'view_custom':
      return getViewCustom(activity)
    case 'form_email':
      return getEmail(activity)
    case 'form_phonenumber':
      return getPhoneNumber(activity)
    case 'form_youtube':
      return getFormYoutube(activity)
    case 'form_text':
      return getTextField(activity)
    case 'form_singlecheck':
      return getSinglecheckField(activity)
    case 'form_multicheck':
      return getMulticheckField(activity)
    case 'form_dropdown':
      return getDropdownField(activity)
    case 'form_textarea':
      return getTextAreaField(activity)
    case 'view_spotify':
      return getViewSpotify(activity)
    case 'form_cryptowallet':
      return getCryptoWallet(activity)
    case 'form_smsverify':
      return getSMSVerify(activity)
    default:
      return null
  }
}

const getReferFriendsDetails = (activity, leadData = null) => {
  leadData = leadData || window._kol.lead
  let rHTML = ''
  let url = ''
  if (activity.url) {
    url = activity.url + (leadData ? '?kid=' + leadData.social_id : '')
  } else {
    const pageURL =
      window.location != window.parent.location
        ? document.referrer
        : document.location.href
    url =
      leadData && leadData.social_url
        ? leadData.social_url
        : `${pageURL}?kid=${leadData && leadData.social_id}`
  }

  let networks = []

  // TODO: Ask scott about saving these as JSON data like the rest instead of as a string in the product.
  try {
    networks = JSON.parse(activity.data.networks)
  } catch {
    networks = activity.data.networks || []
  }
  if (Array.isArray(networks)) {
    networks.forEach((network, index) => {
      if (network.network === 'copy') {
        rHTML += `<div class="input-group  kol-copy-and-paste-sharelink-container kol-share-btns-square kol-share-btns-sm kol-center">
                        <input class="kol-copy-and-paste-sharelink form-control form-control-appended has-set-radius" value="${url}"  >
                        <div class="input-group-btn">
                          <button class="kol-copy-and-paste-icon btn has-set-radius"  ><i class="fas fa-copy" style="display: block;"></i>
               </button></div>
                      </div>`
      } else {
        const shareURL = getNetworkShareURL(network, url)
        const target = network.network === 'email' ? '_self' : '_top'
        const onclick = network.network === 'email' ? "javascript:window.top.location = this.getAttribute('data-share-url');return false;" : "javascript:window.top.open(this.getAttribute('data-share-url'), '_blank', 'width=800,height=500');return false;"
        rHTML += `<a class="kol-social-share-link kol_s_button kol-btn kol-btn-sharing kol-btn-${network.network
          }"  data-share-url="${shareURL}" data-kol-track="true"  href="${shareURL}" target="${target}" class='kol_s_button' onclick="${onclick}">
          <i class='${getActivityIconClasses(network.network)}'></i>
        </a>
        `
      }
    })
  }

  return `<div id="kol_share_links" class="kol-share-btns-md">${rHTML}</div>
    `
}

const getViewCustom = (activity) => {
  const pupa = require('pupa')
  return pupa(activity.data.code, window._kol.lead || [])
}

const getPhoneNumber = (activity) => {
  // Don't allow people to enter form data twice.
  if (activity.completed) {
    return ''
  }

  return `
  <div class="kol-activity-form-item kol-activity-get-phone-number">
    <div class="input-group">
      <input type="tel" id="activity-${activity.id}-phone-number" name="phone_number" class="form-control" placeholder="${activity.data.placeholder || '555-867-5309'}" aria-label="${activity.text}">
      <div class="input-group-btn">
        <button id="activity-${activity.id}-phone-number-btn" type="button" class="btn btn-primary btn-add-form-data"><i class="fas fa-arrow-circle-right"></i></button>
      </div>
    </div>
  </div>
   `
}

const getSMSVerify = (activity) => {
  // Don't allow people to enter form data twice.
  if (activity.completed) {
    return ''
  }

  return `
  <div class="kol-activity-form-item kol-activity-get-sms-verify">
    <div class="input-group">
      <input type="tel" id="activity-${activity.id}-sms-verify" name="sms_code" class="form-control" autocomplete="one-time-code" inputmode="numeric" placeholder="${activity.data.placeholder || '1234'}" aria-label="${activity.text}">
      <div class="input-group-btn">
        <button id="activity-${activity.id}-sms-verify-btn" type="button" class="btn btn-primary btn-add-form-data"><i class="fas fa-arrow-circle-right"></i></button>
      </div>
    </div>
  </div>
   `
}

const getFormYoutube = (activity) => {
  // Don't allow people to enter form data twice.
  if (activity.completed) {
    return ''
  }

  return `
  <div class="kol-activity-form-item kol-activity-get-youtube-video">
    <div class="input-group">
      <input type="text" id="activity-${activity.id}-get-youtube-video" name="youtube_video" class="form-control" placeholder="${activity.data.placeholder || 'https://www.youtube.com/watch?v=eBGIQ7ZuuiU'}" aria-label="${activity.text}">
      <div class="input-group-btn">
        <button id="activity-${activity.id}-youtube-btn" type="button" class="btn btn-primary btn-add-form-data"><i class="fas fa-arrow-circle-right"></i></button>
      </div>
    </div>
  </div>
   `
}

const getCryptoWallet = (activity) => {
  // Don't allow people to enter form data twice.
  if (activity.completed) {
    return ''
  }

  return `
  <div class="kol-activity-form-item kol-activity-get-crypto-wallet">
    <div class="input-group">
      <input type="text" id="activity-${activity.id}-get-crypto-wallet" name="crypto_wallet" class="form-control" placeholder="${activity.data.placeholder || '0x71C7656EC7ab88b098defB751B7401B5f6d8976F'}" aria-label="${activity.text}">
      <div class="input-group-btn">
        <button id="activity-${activity.id}-youtube-btn" type="button" class="btn btn-primary btn-add-form-data"><i class="fas fa-arrow-circle-right"></i></button>
      </div>
    </div>
  </div>
   `
}

const getViewSpotify = (activity) => {
  return `<iframe style="border-radius:12px" src=${activity.url}" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>`
}

const getEmail = (activity) => {
  // Don't allow people to enter form data twice.
  if (activity.completed) {
    return ''
  }

  return `
  <div class="kol-activity-form-item kol-activity-get-email">
    <div class="input-group">
      <input type="email" id="activity-${activity.id}-email" name="email" class="form-control" placeholder="${activity.data.placeholder || 'someone@awesome.co'}" aria-label="${activity.text}">
      <div class="input-group-btn">
        <button id="activity-${activity.id}-email-btn" type="button" class="btn btn-primary btn-add-form-data"><i class="fas fa-arrow-circle-right"></i></button>
      </div>
    </div>
  </div>
   `
}

const getSinglecheckField = (activity) => {
  // Don't allow people to enter form data twice.
  if (activity.completed) {
    return ''
  }

  const name = KOLStrings.cleanFieldName(activity.data.fieldName)
  let checkboxHTML = ''

  checkboxHTML += `
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="activity-${activity.id}-${name}" name="${name}">
      <label class="form-check-label" for="activity-${activity.id}-${name}">
        ${activity.data.placeholder}
      </label>
    </div>
  `

  checkboxHTML += ` <button style="margin-top:10px;" id="activity-${activity.id}-checkbox-btn" type="button" class="btn btn-primary btn-add-form-data"><i class="fas fa-arrow-circle-right"></i></button>`

  return checkboxHTML
}

const getMulticheckField = (activity) => {
  // Don't allow people to enter form data twice.
  if (activity.completed) {
    return ''
  }

  const name = KOLStrings.cleanFieldName(activity.data.fieldName)
  const choices = activity.data.form_choices
  let checkboxHTML = ''

  // Loop through the choices and generate a checkbox for each one
  choices.forEach(function (choice, index) {
    checkboxHTML += `
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="${choice}" id="activity-${activity.id}-${index}" name="${name}">
        <label class="form-check-label" for="activity-${activity.id}-${index}">
          ${choice}
        </label>
      </div>
    `
  })

  checkboxHTML += ` <button style="margin-top:10px;" id="activity-${activity.id}-dropdown-btn" type="button" class="btn btn-primary btn-add-form-data"><i class="fas fa-arrow-circle-right"></i></button>`

  return checkboxHTML
}

const generateBootstrapSelectHTML = (activity) => {
  const name = KOLStrings.cleanFieldName(activity.data.fieldName)
  const choices = activity.data.form_choices
  // Start the select element with the specified name attribute
  let selectHTML = `<select id="activity-${activity.id}-select"  class="form-control" name="${name}">`
  // Add a placeholder option at the beginning
  selectHTML += `<option value="" disabled selected>${activity.data.placeholder || 'Pick from the list...'}</option>`
  // Add option elements for each choice
  choices.forEach(function (choice) {
    selectHTML += `<option value="${choice}">${choice}</option>`
  })

  // Close the select element
  selectHTML += '</select>'

  return selectHTML
}

const getDropdownField = (activity) => {
  // Don't allow people to enter form data twice.
  if (activity.completed) {
    return ''
  }
  return `
  <div class="kol-activity-form-item kol-activity-get-text">
    <div class="input-group">
      ${generateBootstrapSelectHTML(activity)}

      <div class="input-group-btn">
        <button id="activity-${activity.id}-dropdown-btn" type="button" class="btn btn-primary btn-add-form-data"><i class="fas fa-arrow-circle-right"></i></button>
      </div>
    </div>
  </div>
  `
}


const getTextField = (activity) => {
  // Don't allow people to enter form data twice.
  if (activity.completed) {
    return ''
  }

  const fname = KOLStrings.cleanFieldName(activity.data.fieldName)
  return `
  <div class="kol-activity-form-item kol-activity-get-text">
    <div class="input-group">
      <input id="activity-${activity.id}-text-field" name="${fname}" placeholder="${activity.data.placeholder || 'Add the goods here.'}" class="form-control" >

      <div class="input-group-btn">
        <button id="activity-${activity.id}-text-field-btn" type="button" class="btn btn-primary btn-add-form-data"><i class="fas fa-arrow-circle-right"></i></button>
      </div>
    </div>
  </div>
  `
}

const getTextAreaField = (activity) => {
  // Don't allow people to enter form data twice.
  if (activity.completed) {
    return ''
  }

  const fname = KOLStrings.cleanFieldName(activity.data.fieldName)
  return `
  <div class="kol-activity-form-item kol-activity-get-text-area">
    <div class="input-group flex-row">
      <textarea id="activity-${activity.id}-text-field" name="${fname}" placeholder="${activity.data.placeholder || 'Add the goods here.'}" class="form-control flex-col" style="min-height: 75px; height:75px;resize: vertical;"></textarea>

      <div class="input-group-btn flex-col">
        <button id="activity-${activity.id}-text-field-btn " type="button" class="btn btn-primary btn-add-form-data" style="height:75px;"><i class="fas fa-arrow-circle-right"></i></button>
      </div>
    </div>
  </div>
  `
}

const getTikTokEmbed = (activity) => {
  return activity.data.code
}

const getTwitterFollowDetails = (activity) => {
  return `
    <a class="twitter-follow-button"
      href="${activity.url}"
      data-size="large" data-show-count="false">${activity.text}</a>
    `
}

const getReTweetButton = (activity) => {
  const tweet_id = getTweetIdFromUrl(activity.url)
  return `
    <a class="btn btn-twitter" href="https://twitter.com/intent/retweet?tweet_id=${tweet_id}">Retweet</a>
    `
}

const getTweetEmbed = (activity) => {
  return `
      <blockquote class="twitter-tweet">
       <a href="${activity.url}">${activity}</a>
      </blockquote>
    `
}

const getTweetButton = (activity) => {
  const pupa = require('pupa')
  const share_text = pupa(activity.data.shareText, window._kol.lead || [])
  return `
      <blockquote>${share_text}</blockquote>
      <a class="btn btn-twitter" href="https://twitter.com/intent/tweet?text=${encodeURIComponent(
        share_text
      )}">${activity.text}</a>
    `
}

const getSMSButton = (activity) => {
  const pupa = require('pupa')
  const share_text = pupa(activity.data.shareText, window._kol.lead || [])
  return `
      <blockquote>${share_text}</blockquote>
      <a class="btn btn-sms btn-sharing kol-detail-click-points" href="sms:?&body=${encodeURIComponent(
        share_text
      )}">${activity.text}</a>
    `
}

const getInstagramPost = (activity) => {
  return `
      <blockquote class="instagram-media" data-instgrm-captioned data-instgrm-permalink="${activity.url}" data-instgrm-version="12" style=" background:#FFF; border:0; border-radius:3px; box-shadow:0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15); margin: 1px; max-width:540px; min-width:326px; padding:0; width:99.375%; width:-webkit-calc(100% - 2px); width:calc(100% - 2px);">
      <a href="${activity.url}">Visit Post</a>
      </blockquote >
    `
}

const getYouTubeVideoId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)

  if (match && match[2].length === 11) {
    return match[2]
  } else {
    // is youtube short?
    if (url.replace('https://www.youtube.com/shorts/', '')) {
      return url.replace('https://www.youtube.com/shorts/', '')
    }
  }

  return null
}

const getYouTubeVideo = (action) => {
  const videoId = getYouTubeVideoId(action.url)
  const iframeMarkup = `<div class="embed-responsive embed-responsive-16by9">
        <iframe  src="//www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>
      </div>
        `
  return iframeMarkup
}

const getLinkedInShare = (activity) => {
  return `
      <a class="btn btn-linkedin btn-sharing kol-detail-click-points" href="https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        activity.url
      )}" target="_blank">${activity.text}</a>
    `
}

const getLinkedInFollow = (activity) => {
  return `
    <div class='kol-detail-click-points' style="display:inline-block;"><script type="IN/FollowCompany" data-id="${activity.data['company-id']}" ></script></div>
    `
}

const getFacebookPost = (activity) => {
  return `
      <div class="fb-post" data-href="${activity.url}" data-show-text="true" data-width="320"></div>
    `
}

const getTweetIdFromUrl = (url) => {
  return url.substring(url.lastIndexOf('/') + 1)
}

export default getActivityDetailsHTML
