import { fromEntries } from './objects'
export const getQS = (name) => {
  return window._kol.queryString[name]
}

export const parse = (location = window.location) => {
  return fromEntries(new URLSearchParams(location.search))
}

export const isEmbed = (location = window.location) => {
  if (window._kol.queryString) {
    return window._kol.queryString.__embed === 'true'
  } else {
    const url = new URL(location)
    return url.searchParams && url.searchParams.get('__embed') === 'true'
  }
}

export const isPreview = (location = window.location) => {
  if (window._kol.queryString) {
    return window._kol.queryString.preview === 'true'
  } else {
    const url = new URL(location)
    return url.searchParams && url.searchParams.get('preview') === 'true'
  }
}
